/* 폰트 */
@font-face {
  font-family: "SCDream6";
  src: url("./assets/fonts/SCDream6.otf");
}

/* APP */
.App {
  text-align: center;
  flex: 1;
  display: flex;
}
